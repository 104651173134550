import { DownIcon } from 'icons'
import React from 'react'

const RowsPerPageSelector = ({ options, current, onChange }) => (
  <div className="flex items-center mr-2 lg:mr-4">
    <select
      value={current}
      onChange={(e) => onChange(Number(e.target.value))}
      className="border bg-transparent rounded px1 lg:px-2 py-1 text-xs md:text-sm"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
)

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  visiblePages = 5,
  className = '',
  rowsPerPageOptions = [10, 25, 50, 100],
  currentRowsPerPage,
  onRowsPerPageChange,
}) => {
  const generatePageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
    let endPage = Math.min(totalPages, startPage + visiblePages - 1)

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(1, endPage - visiblePages + 1)
    }

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }

  const handlePageChange = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages && onPageChange) {
      onPageChange(page)
    }
  }

  const pageNumbers = generatePageNumbers()

  return (
    <div className={`flex items-center justify-between py-2 md:py-4 ${className}`}>
      <RowsPerPageSelector options={rowsPerPageOptions} current={currentRowsPerPage} onChange={onRowsPerPageChange} />
      <div className="flex items-center">
        <NavButton disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
          <DownIcon fill="#374151" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90 " />
        </NavButton>
        {pageNumbers[0] > 1 && (
          <>
            <PageItem page={1} isActive={currentPage === 1} onClick={handlePageChange} />
            {pageNumbers[0] > 2 && <span className="px-2">...</span>}
          </>
        )}
        {pageNumbers.map((page) => (
          <PageItem key={page} page={page} isActive={currentPage === page} onClick={handlePageChange} />
        ))}
        {pageNumbers[pageNumbers.length - 1] < totalPages && (
          <>
            {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && <span className="px-2">...</span>}
            <PageItem page={totalPages} isActive={currentPage === totalPages} onClick={handlePageChange} />
          </>
        )}
        <NavButton disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
          <DownIcon fill="#374151" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90" />
        </NavButton>
      </div>
    </div>
  )
}

const PageItem = ({ page, isActive, onClick }) => (
  <button
    className={`px-3 py-[0.3rem] relative h-[30px] w-[30px] mx-1 rounded-[50%] hover:border text-xs md:text-sm ${isActive ? 'bg-[#12367F10] text-primary-dark' : ' text-gray-700'}`}
    onClick={() => onClick(page)}
  >
    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 font-medium -translate-y-1/2">{page}</span>
  </button>
)

const NavButton = ({ disabled, onClick, children }) => (
  <button
    className={`px-3 py-[0.3rem] mx-1 relative h-[30px] w-[30px] rounded-[50%] text-sm ${disabled ? 'bg-gray-200 cursor-not-allowed' : ''}`}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
)

export { Pagination }
