import { Button } from 'components/lib'
import { SearchIcon, TargetIcon } from 'icons'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { useRef } from 'react'
import Tippy from '@tippyjs/react'
import { useSearch } from './searchContext'
import { useWindowSize } from 'hooks/useWindowSize.hook'

const SearchForm = ({ handleSubmit, className, autoFocus }) => {
  const { searchInputValue, setSearchInputValue, strictSearch, setStrictSearch, setSubmittedSearchValue } = useSearch()
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth < 768

  const inputRef = useRef(null)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    setSubmittedSearchValue(searchInputValue)
    if (handleSubmit) handleSubmit(searchInputValue)
  }

  const handleSearchInputChange = (e) => {
    setSearchInputValue(e.target.value)
  }

  const handleInputClear = () => {
    setSearchInputValue(null)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleFormSubmit(e)
  }

  const toggleStrictSearch = () => {
    setStrictSearch((prev) => !prev)
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      noValidate
      className={
        'group flex items-center bg-white p-3 md:p-4 rounded-full my-3 md:my-4 transition-all drop-shadow-sm border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 max-w-[800px] w-full mx-auto ' +
        className
      }
    >
      <input
        type="text"
        autoFocus={autoFocus}
        id="searchInput"
        value={searchInputValue || ''}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by keyword, description, or simply ask a question..."
        className="flex-grow appearance-none bg-transparent border-none outline-none text-sm md:text-base px-2 placeholder-gray-400"
        autoComplete="off"
        ref={inputRef}
      />
      <div className="flex items-center">
        {searchInputValue && (
          <Button icon={'x'} size={isMobile ? 18 : 20} color={'dark'} className="md:mr-2" action={handleInputClear} />
        )}
        <div className="w-px h-6 bg-gray-300 mx-2 hidden sm:block"></div>
        <div className="flex gap-2 px-2">
          <Tippy touch={false} content={'Strict Search'} arrow={roundArrow} animation="shift-away" inertia={true}>
            <button type="button" onClick={toggleStrictSearch}>
              <TargetIcon fill={strictSearch ? undefined : '#6c6c6c'} />
            </button>
          </Tippy>
          <button type="submit" className=" " id="inputField">
            <SearchIcon />
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchForm
