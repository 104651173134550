import SearchForm from './searchForm'
import useCallAPI from 'components/hooks/callApi'
import { useAccess, useWindowSize } from 'hooks'
import { Link, useSearchParams } from 'react-router-dom'
import ResultItem from './individualResults'
import { Button, Drawer, Pagination, Slider, ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getCountryKeyByValue, getPracticeAreasByCountry, moduleIds } from '@lawcyborg/packages'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import { DebugContext } from 'app/debug'
import { useStopwatch } from 'hooks/useStopwatch.hook'
import { useSearch } from './searchContext'
import SearchFilters from 'views/searchAI/searchFilters'
import { useAuth } from 'app/auth'
import { MobileViewportWrapper } from './AIChatWindow'
import { findCheckedOptions } from 'components/filterStateful/utils'

const DEBUG_MIN_RESULT_AMOUNT = 1
const DEBUG_MAX_RESULT_AMOUNT = 400
const DEBUG_DEFAULT_RESULT_AMOUNT = 50

const DebugTools = ({ onResultAmountCommit, serverQueryTime, elapsedTime }) => {
  const [resultsReturned, setResultsReturned] = useState(DEBUG_DEFAULT_RESULT_AMOUNT)

  return (
    <div className="flex flex-col">
      <h2 className="uppercase font-semibold text-sm text-primary-dark m-1 py-2">Result Amount</h2>
      <div className="flex">
        <p className="mr-3 mt-1">{DEBUG_MIN_RESULT_AMOUNT}</p>
        <Slider
          onValueCommit={(e) => {
            onResultAmountCommit(e[0])
            setResultsReturned(e[0])
          }}
          max={DEBUG_MAX_RESULT_AMOUNT}
          min={DEBUG_MIN_RESULT_AMOUNT}
          step={1}
          defaultValue={DEBUG_DEFAULT_RESULT_AMOUNT}
          hoverValue={resultsReturned}
        />
        <p className="ml-3 mt-1">{DEBUG_MAX_RESULT_AMOUNT}</p>
        <Tippy
          content={`Adjust how many documents are returned in the search results.`}
          arrow={roundArrow}
          animation="shift-away"
          inertia={true}
        >
          <div className="px-3">
            <Button
              icon={'alert-circle'}
              color={'dark'}
              size={20}
              className={
                'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
              }
            />
          </div>
        </Tippy>
        <div>
          <p>{`Server Query Time (s): ${serverQueryTime}`}</p>
          <p>{`Elapsed Time (s): ${elapsedTime / 1000}`}</p>
        </div>
      </div>
    </div>
  )
}

const FilterContent = ({
  setRelevancyVsRecency,
  relevancyVsRecency,
  availablePracticeAreas,
  hasAccess,
  selectedPracticeAreaId,
  className = '',
}) => {
  return (
    <div className={`${className}`}>
      <div className="gap-4 my-2 lg:my-4">
        <h2 className="uppercase font-semibold text-xs lg:text-sm text-primary-dark py-1 lg:py-2">Recency</h2>
        <div className="flex">
          <Slider
            onValueCommit={(e) => setRelevancyVsRecency(e[0])}
            max={1}
            min={0}
            step={0.01}
            defaultValue={relevancyVsRecency || 0}
          />
          <Tippy
            touch={false}
            content={`Adjust how much recency is taken into account for a given search.`}
            arrow={roundArrow}
            animation="shift-away"
            inertia={true}
          >
            <div className="px-3">
              <Button icon={'alert-circle'} color={'dark'} size={20} />
            </div>
          </Tippy>
        </div>
      </div>
      <SearchFilters
        practiceArea={Object.values(availablePracticeAreas).filter(
          (pa) => hasAccess(pa.id) || hasAccess(moduleIds.NZ_PRACTICE_AREA_BUNDLE)
        )}
      />
      {!selectedPracticeAreaId && (
        <Tippy
          touch={false}
          content={`Law Cyborg's Elite Plan is in early access. If you have feedback or suggestions, please get in touch.`}
          arrow={roundArrow}
          animation="shift-away"
          inertia={true}
        >
          <div className="absolute right-4 bottom-4">
            <Button
              icon={'alert-circle'}
              color={'dark'}
              size={20}
              className={
                'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
              }
            />
          </div>
        </Tippy>
      )}
    </div>
  )
}

const FilterSection = ({
  setRelevancyVsRecency,
  relevancyVsRecency,
  availablePracticeAreas,
  hasAccess,
  selectedPracticeAreaId,
}) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth && windowWidth <= 767
  const [isOpen, setIsOpen] = useState(false)

  const filterProps = {
    setRelevancyVsRecency,
    relevancyVsRecency,
    availablePracticeAreas,
    hasAccess,
    selectedPracticeAreaId,
  }

  return (
    <>
      {isMobile ? (
        <Drawer
          triggerButton={
            <Button
              icon={'sliders'}
              color={'#475569'}
              action={() => setIsOpen((prev) => !prev)}
              className={'!absolute top-5 right-5 '}
            />
          }
          title="Filter"
          open={isOpen}
          onOpenChange={setIsOpen}
          sheetSide="right"
          className={'bg-red-500'}
        >
          <div className="p-0 md:p-4">
            <h2 className="font-bold text-base lg:text-xl text-[#0C254D]">Filter</h2>
            <FilterContent {...filterProps} />
          </div>
        </Drawer>
      ) : (
        <div className="hidden md:block right my-4 lg:ml-8 min-w-64 max-w-72">
          <div className="flex w-full pb-5 border-b-[1px]">
            <h2 className="inline font-bold text-xl text-[#0C254D]">Filter</h2>
          </div>
          <FilterContent {...filterProps} className="items-center px-2 mt-8" />
        </div>
      )}
    </>
  )
}

const SearchResults = () => {
  const [isLogoVisible, setIsLogoVisible] = useState(false)
  const handleToggle = () => {
    setIsLogoVisible((prev) => {
      return !prev
    })
  }
  const viewContext = useContext(ViewContext)
  const debugContext = useContext(DebugContext)
  const hasAccess = useAccess()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    searchResults,
    setSearchResults,
    searchInputValue,
    strictSearch,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    setSubmittedSearchValue,
    selectedNamespaces,
    selectedCategories,
    selectedPracticeAreaId,
    relevancyVsRecency,
    setRelevancyVsRecency,
    searchQueued,
    setSearchQueued,
  } = useSearch()
  const { accounts } = useAuth()
  const userCountry = getCountryKeyByValue(accounts[0].country)
  // FIXME: this is a temporary fix for the region code, we need to get the region from the user's account
  const region = userCountry === 'au' ? 'nsw' : undefined
  const availablePracticeAreas = getPracticeAreasByCountry(userCountry, region)

  const startIndex = (currentPage - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const [paginatedResults, setPaginatedResults] = useState(
    searchResults ? searchResults.slice(startIndex, endIndex) : []
  )

  const { width: windowWidth } = useWindowSize()
  const [search, results, loading, error] = useCallAPI({
    url: '/api/search',
    method: 'POST',
  })

  const isDesktopWide = windowWidth > 1280
  const isDesktop = windowWidth > 1080

  const [resultsReturned, setResultsReturned] = useState(undefined)
  const [serverQueryTime, setServerQueryTime] = useState(0)
  const { elapsedTime, start, stop, reset } = useStopwatch()

  useEffect(() => {
    if (results?.data?.results) {
      setSearchResults(results.data.results)
    }
  }, [results, setSearchResults])

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const startIndex = (currentPage - 1) * rowsPerPage
      const endIndex = startIndex + rowsPerPage
      const newPaginated = searchResults.slice(startIndex, endIndex)
      setPaginatedResults(newPaginated)
    }
  }, [currentPage, rowsPerPage, searchResults, searchInputValue, setSearchParams])

  const totalPages = searchResults ? Math.ceil(searchResults.length / rowsPerPage) : 1

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRowsPerPageChange = (newRows) => {
    setRowsPerPage(newRows)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (results?.data) stop()
    if (results?.data?.queryTime) setServerQueryTime(results.data.queryTime)
  }, [results, stop])

  useEffect(() => {
    if (debugContext.debug) {
      setServerQueryTime(0)
      setResultsReturned(DEBUG_DEFAULT_RESULT_AMOUNT)
      reset()
    } else setResultsReturned(undefined)
  }, [debugContext.debug, reset])

  const handleSubmit = useCallback(async () => {
    const trimmedSearch = searchInputValue?.trim() || ''

    if (trimmedSearch === '') {
      viewContext.notification.show('Please enter a search term', 'error')
      return
    }

    setSubmittedSearchValue(searchInputValue)
    setPaginatedResults([])

    if (debugContext.debug) {
      reset()
      start()
      setServerQueryTime(0)
    }

    const filters = {
      practice_area: selectedPracticeAreaId,
      namespaces: findCheckedOptions(selectedNamespaces).flat.map((option) => option.value),
      categories: findCheckedOptions(selectedCategories).nested,
    }

    search({
      requestData: {
        query: searchInputValue,
        filters,
        relevantVsRecent: relevancyVsRecency,
        searchMode: strictSearch ? 'strict' : 'smart',
        auto: !selectedPracticeAreaId,
        resultsReturned,
      },
    })

    setCurrentPage(1)
  }, [
    search,
    searchInputValue,
    selectedPracticeAreaId,
    selectedCategories,
    selectedNamespaces,
    relevancyVsRecency,
    strictSearch,
    resultsReturned,
    setCurrentPage,
    setSubmittedSearchValue,
    viewContext.notification,
    debugContext.debug,
    reset,
    start,
  ])

  useEffect(() => {
    const hasCategories = Object.keys(selectedCategories || {}).length > 0
    const hasNamespaces = Object.keys(selectedNamespaces || {}).length > 0
    const hasInput = searchInputValue !== ''

    if (searchQueued && hasCategories && hasNamespaces && hasInput) {
      setSearchQueued(false)
      handleSubmit()
    }
  }, [searchQueued, selectedCategories, selectedNamespaces, searchInputValue, setSearchQueued, handleSubmit])

  return (
    <div className="md:flex !pt-4 md:gap-2 h-full overflow-y-auto justify-between">
      {isDesktopWide && (
        <div>
          <div className={`logoMargin text-xl my-5 mx-8 font-bold ${isLogoVisible ? '' : 'hidden'}`}>
            <Link className="text-primary-dark" to="/search">
              <img
                className="text-primary-dark h-14"
                src="https://ominous.nz/storage/Cropped-Seafarer-Logo.svg"
                alt="Law Cyborg Search Logo"
              />
            </Link>
          </div>
          <div className={`w-[395px] h-[100vh] ${isLogoVisible ? 'hidden' : ''}`}>
            <MobileViewportWrapper />
          </div>
        </div>
      )}
      {isDesktopWide && (
        <Tippy touch={false} content={`Show/hide AI`} arrow={roundArrow} animation="shift-away" inertia={true}>
          <div className="-ml-1 mt-7 h-8">
            <Button
              icon="cpu"
              color="dark"
              size={16}
              action={handleToggle}
              className="bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20"
            />
          </div>
        </Tippy>
      )}
      <div className="left flex-1">
        {debugContext.debug && (
          <DebugTools
            onResultAmountCommit={(val) => setResultsReturned(val)}
            serverQueryTime={serverQueryTime}
            elapsedTime={elapsedTime}
          />
        )}
        <div className="results mx-2 lg:mx-4">
          <div className="w-full">
            <SearchForm handleSubmit={handleSubmit} searchParams={searchParams} className="bg-white" />
          </div>
          <div className="results px-0 lg:px-2 my-4 max-w-[800px] mx-auto">
            {loading ? (
              <p className="ml-4">Loading...</p>
            ) : error ? (
              <p className="text-red-500">An error occurred while fetching results.</p>
            ) : (
              searchResults.length > 0 && (
                <>
                  <span className="resultSummary text-xs md:text-sm text-[#475569] ml-4">
                    {`Showing ${(currentPage - 1) * rowsPerPage + 1} to ${Math.min(
                      currentPage * rowsPerPage,
                      searchResults.length
                    )} of ${searchResults.length} results for "${searchInputValue}"`}
                  </span>

                  {paginatedResults.map((result, index) => (
                    <div key={index} className="border-b py-2">
                      <ResultItem
                        id={index + (currentPage - 1) * rowsPerPage}
                        {...result}
                        highlight={searchInputValue}
                      />
                    </div>
                  ))}

                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    visiblePages={isDesktop ? 5 : 3}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    currentRowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    className="custom-pagination"
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
      <FilterSection
        availablePracticeAreas={availablePracticeAreas}
        hasAccess={hasAccess}
        setRelevancyVsRecency={setRelevancyVsRecency}
        relevancyVsRecency={relevancyVsRecency}
        selectedPracticeAreaId={selectedPracticeAreaId}
      />
    </div>
  )
}

export default SearchResults
