import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { sanitizeHTML } from 'utils'
import { Button } from 'components/lib'
import './styles/document.css'

/**
 * Recursively traverse the DOM and wrap matching text nodes.
 * @param {Node} node - The current DOM node.
 */
function traverseAndHighlight(node, regex, countObj = { count: 0 }) {
  // If the node is a text node
  if (node.nodeType === Node.TEXT_NODE) {
    const parent = node.parentNode
    const text = node.textContent
    let match
    let lastIndex = 0
    const fragments = []

    // Reset the regex lastIndex in case it's been used before
    regex.lastIndex = 0

    // Iterate over all matches in the text node
    while ((match = regex.exec(text)) !== null) {
      const matchedText = match[0]
      const matchStart = match.index
      const matchEnd = regex.lastIndex

      if (matchEnd - matchStart === 0) {
        break
      }

      // Add the text before the match as a text node
      if (matchStart > lastIndex) {
        const before = text.slice(lastIndex, matchStart)
        fragments.push(document.createTextNode(before))
      }

      // Create the <mark> element for the matched text
      const mark = document.createElement('mark')
      mark.className = 'search-result'
      countObj.count += 1
      mark.id = `search-result-${countObj.count}`
      mark.textContent = matchedText
      fragments.push(mark)

      lastIndex = matchEnd
    }

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      const after = text.slice(lastIndex)
      fragments.push(document.createTextNode(after))
    }

    // If there are matches, replace the original text node with the new fragments
    if (fragments.length > 0) {
      fragments.forEach((fragment) => {
        parent.insertBefore(fragment, node)
      })
      parent.removeChild(node)
    }
  } else {
    // If the node is an element node, recursively traverse its children
    node.childNodes.forEach((child) => traverseAndHighlight(child, regex, countObj))
  }
  return countObj.count
}

const fetchHtmlContent = async (url) => {
  const response = await fetch(url)
  if (response.ok) {
    const responseContentType = response.headers.get('Content-type')
    if (responseContentType && responseContentType.includes('text/html')) {
      const text = await response.text()
      return sanitizeHTML(text)
    }
  }
  throw new Error('Failed to fetch HTML content')
}

const fetchPdfContent = async (url) => {
  const response = await fetch(url)
  if (response.ok) {
    const responseContentType = response.headers.get('Content-type')
    if (responseContentType && responseContentType.includes('application/pdf')) {
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    }
  }
  throw new Error('Failed to fetch PDF content')
}

const HtmlContent = ({ html_url, pdf_url, searchDocument, onContentTypeChange, regexMode }) => {
  const [contentType, setContentType] = useState(null)
  const [contentData, setContentData] = useState('')
  const [loading, setLoading] = useState(true)
  const [currentMatch, setCurrentMatch] = useState(0)
  const contentRef = useRef(null)

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true)
      setContentType(null)

      try {
        if (html_url) {
          try {
            let html = await fetchHtmlContent(html_url)
            // Sneaky hack as is Friday, should really re generate docs for this fix
            html = html.replace('margin: 20px 100px', 'margin: 20px 0')
            setContentType('text/html')
            setContentData(html)
            if (onContentTypeChange) onContentTypeChange('text/html')
            return
          } catch (error) {
            console.warn('Error fetching HTML content:', error)
          }
        }
        if (pdf_url) {
          try {
            const pdfBlobUrl = await fetchPdfContent(pdf_url)
            setContentType('application/pdf')
            setContentData(pdfBlobUrl)
            if (onContentTypeChange) onContentTypeChange('application/pdf')
            return
          } catch (error) {
            console.warn('Error fetching PDF content:', error)
          }
        }
        setContentType('error')
        if (onContentTypeChange) onContentTypeChange('error')
      } catch (error) {
        console.error('Error fetching content:', error)
        setContentType('error')
        if (onContentTypeChange) onContentTypeChange('error')
      } finally {
        setLoading(false)
      }
    }

    fetchContent()

    return () => {
      if (contentData && contentType === 'application/pdf') {
        URL.revokeObjectURL(contentData)
      }
    }
  }, [html_url, pdf_url, onContentTypeChange])

  const { highlightedContent, totalMatches } = useMemo(() => {
    if (contentType !== 'text/html' || !contentData) {
      return { highlightedContent: '', totalMatches: 0 }
    }
    if (!searchDocument?.trim()) {
      return { highlightedContent: contentData, totalMatches: 0 }
    }

    let regex
    if (regexMode) {
      try {
        regex = new RegExp(searchDocument, 'gi')
      } catch (error) {
        console.warn('Invalid regex:', error)
        return { highlightedContent: contentData, totalMatches: 0 }
      }
    } else {
      const escaped = searchDocument.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      regex = new RegExp(`(${escaped})`, 'gi')
    }
    const parser = new DOMParser()
    const doc = parser.parseFromString(contentData, 'text/html')
    const count = traverseAndHighlight(doc.body, regex)
    const serializer = new XMLSerializer()
    const highlightedHTML = serializer.serializeToString(doc)
    return { highlightedContent: highlightedHTML, totalMatches: count }
  }, [contentData, contentType, searchDocument, regexMode])

  useEffect(() => {
    if (contentType === 'text/html' && currentMatch > 0 && contentRef.current) {
      const allMatches = contentRef.current.querySelectorAll('.search-result')
      allMatches.forEach((match, index) => {
        match.classList.toggle('bg-orange-300', index + 1 === currentMatch)
      })

      contentRef.current
        .querySelector(`#search-result-${currentMatch}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [currentMatch, contentType])

  const handleNavigation = useCallback(
    (direction) => () => {
      setCurrentMatch((prev) =>
        direction === 'next' ? (prev < totalMatches ? prev + 1 : 1) : prev > 1 ? prev - 1 : totalMatches
      )
    },
    [totalMatches]
  )

  if (loading) {
    return <div>Loading...</div>
  }

  if (contentType === 'text/html') {
    return (
      <div>
        {totalMatches > 0 && (
          <div className="absolute z-10 top-4 right-4 bg-white p-2 rounded shadow-md flex items-center space-x-2">
            <Button variant="outline" icon="chevron-left" size="icon" action={handleNavigation('prev')} />
            <span className="text-sm my-auto">
              {currentMatch} of {totalMatches}
            </span>
            <Button variant="outline" icon="chevron-right" size="icon" action={handleNavigation('next')} />
          </div>
        )}
        <div ref={contentRef} dangerouslySetInnerHTML={{ __html: highlightedContent }} />
      </div>
    )
  } else if (contentType === 'application/pdf') {
    return <iframe src={contentData} title="PDF Viewer" width="100%" height="1200px" style={{ border: 'none' }} />
  } else if (contentType === 'error' && !loading) {
    return <div>Sorry, the document you are looking for is not there.</div>
  }
}

export default HtmlContent
