import { useWindowSize } from 'hooks/useWindowSize.hook'

const DownIcon = ({ width: customWidth, height: customHeight, fill = '#12367F', ...props }) => {
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth < 768
  const width = customWidth ?? (isMobile ? 10 : 12)
  const height = customHeight ?? (isMobile ? 6 : 7)
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 7" {...props}>
      <path
        fill={fill}
        d="M6.027 7a.849.849 0 0 1-.61-.254L.252 1.5a.885.885 0 0 1 0-1.242.857.857 0 0 1 1.222 0l4.552 4.635L10.58.266a.852.852 0 0 1 1.168.046.882.882 0 0 1 .045 1.187L6.63 6.746A.854.854 0 0 1 6.027 7Z"
      />
    </svg>
  )
}

export { DownIcon }
