import { useWindowSize } from 'hooks'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import SearchForm from 'views/searchAI/searchForm'

// JL Consider to delete this file no longer used
const DocumentHeader = ({ className, SearchClassName }) => {
  const { width: windowWidth } = useWindowSize()

  return (
    <div className={cn('flex justify-between items-center', className)}>
      {windowWidth > 1280 ? (
        <div className="my-6">
          <Link className="text-primary-dark " to="/search">
            <img
              className="text-primary-dark h-14"
              src="https://ominous.nz/storage/Cropped-Seafarer-Logo.svg"
              alt="Law Cyborg Search Logo"
            />
          </Link>
        </div>
      ) : null}

      <div className="w-full">
        <SearchForm className={cn('bg-white', SearchClassName)} />
      </div>
    </div>
  )
}

export { DocumentHeader }
