import brand from './assets/brand4.svg'
import SearchForm from './searchForm'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ViewContext } from 'components/lib'
import { useSearch } from './searchContext'

const SearchTool = () => {
  const viewContext = useContext(ViewContext)
  const navigate = useNavigate()
  const { setSearchQueued } = useSearch()

  const handleSubmit = (inputValue) => {
    if (!inputValue || inputValue.trim() === '') {
      viewContext.notification.show('Please enter a search term', 'error')
      return
    }
    setSearchQueued(true)
    navigate(`/search/results`)
  }

  return (
    <div className="flex h-[calc(100%-10rem)]">
      <div className="h-fit m-auto w-full px-4 sm:px-6 md:px-8">
        <div className="mx-auto w-fit mb-16">
          <img src={brand} alt="lawcyborg" />
        </div>
        <SearchForm autoFocus handleSubmit={handleSubmit} />
        <span className="text-sm text-[#475569] block text-center mt-4">
          The Taxation (Budget Measures) Bill, assented on 4 June 2024, adjusts the personal income tax thresholds.
        </span>
      </div>
    </div>
  )
}

export default SearchTool
