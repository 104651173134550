import { useState, useRef } from 'react'
import {
  ChatInputForm,
  ConversationDisplay,
  useConversation,
  ChatDisclaimer,
  useConversationAutoScroll,
} from 'components/lib'

import { disclaimers, general } from './definitions'

import ChatHistory from 'components/ai/chatHistory'
import { chatTypeIds } from '@lawcyborg/packages'

export function AiGeneral({ isChatWindow }) {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)

  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    loadingConversation,
    branch,
    swapBranch,
    loadConversation,
    stopStreaming,
    loadConversations,
    historyCached,
    conversationId,
  } = useConversation({ ...general, practiceArea: chatTypeIds.GENERAL })
  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>

  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative overflow-x-hidden">
      <ChatHistory
        enableRefresh={exchanges.length}
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f5f5f5'}
        onOpen={loadConversations}
        loading={!historyCached}
        isStreaming={!loading && !canSend}
        isChatWindow={isChatWindow}
      />
      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%]  no-scrollbar" id="chat">
        {!(loadingConversation || exchanges.length) && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={disclaimers.nz[chatTypeIds.GENERAL]}
            isChatWindow={isChatWindow}
          />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          loadingConversation={loadingConversation}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
          isChatWindow={isChatWindow}
        />
      </div>

      <div
        className={`${isChatWindow ? '' : 'lg:w-[67%] md:w-[90%]'} self-center relative webfill flex items-center justify-center `}
      >
        {/* <RefreshButton enabled={exchanges.length} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          isChatWindow={isChatWindow}
        />
      </div>
    </div>
  )
}
