import dayjs from 'dayjs'
import { OS_TYPE } from './constants'
import { countries } from '@lawcyborg/packages'

// Function to format the date
export const categorizeDate = (timeString) => {
  const date = dayjs(timeString)
  const today = dayjs().startOf('day')

  const diffInDays = today.diff(date.startOf('day'), 'day')

  if (diffInDays === 0) {
    return 'Today'
  } else if (diffInDays === 1) {
    return 'Yesterday'
  } else if (diffInDays <= 7) {
    return `Previous 7 Days`
  } else if (diffInDays <= 30) {
    return `Previous 30 Days`
  } else {
    return date.format('MMMM')
  }
}

export const formatDate = (date, format = 'DD-MM-YYYY') => {
  let localDateString = new Date(date)
  return dayjs(localDateString).format(format)
}
export function getSerializedDns(data) {
  if (!data) return {}
  const result = {}

  function recurse(obj, parentCategory = '') {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if ('namespace' in obj[key]) {
          let resultKey = key
          // LV This is a very dirty hack to get the worker lambdas to work
          // without needing to do a coupled release. Ideally the whole way
          // we send namespaces should be redone. This fix is good enough
          // until we (most likely me) change the way we send namespaces.
          if (key === 'Income Tax Act 2007') resultKey = 'Income Tax Act'

          result[resultKey] = {
            namespace: obj[key].namespace,
            checked: obj[key].checked,
          }
        } else {
          recurse(obj[key], key)
        }
      } else if (parentCategory && key === 'namespace') {
        result[parentCategory] = {
          namespace: obj[key],
          checked: obj.checked,
        }
      }
    }
  }

  recurse(data)
  return result
}

export function getSerializedDnsV2(data) {
  if (!data) return {}
  const namespaces = []

  function recursiveSearch(obj) {
    // Check if the object has the "checked" key set to true
    if (obj.hasOwnProperty('checked') && obj.checked === true) {
      // If "namespace" key exists, add its value to the array
      if (obj.hasOwnProperty('namespace')) {
        namespaces.push(obj.namespace)
      }
    }

    // Iterate over keys in the current object
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively search nested objects
        recursiveSearch(obj[key])
      }
    }
  }

  // Start the recursive search with the initial object
  data.Auto.checked ? namespaces.push('auto') : recursiveSearch(data)

  return namespaces
}

export const highlightText = (content, highlight) => {
  if (!highlight) return content
  const regex = new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi')
  return content.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-medium">
        {part}
      </span>
    ) : (
      part
    )
  )
}

export const truncateContent = (content, maxLength) => {
  return content.length > maxLength ? `${content.substring(0, maxLength)}...` : content
}

export function cn(...inputs) {
  const classes = inputs.flatMap((input) => {
    if (typeof input === 'string') {
      return input.split(' ')
    }
    if (Array.isArray(input)) {
      return input.flatMap((item) => cn(item))
    }
    if (typeof input === 'object' && input !== null) {
      return Object.entries(input)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => key)
    }
    return []
  })

  const uniqueClasses = new Set(classes)
  return Array.from(uniqueClasses).join(' ')
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  for (const [os, pattern] of Object.entries(OS_TYPE)) {
    if (pattern.test(userAgent)) {
      return os
    }
  }

  return 'unknown'
}

/**
 * Custom function to sanitize HTML by removing potentially dangerous elements and attributes.
 * @param {string} html - The HTML string to sanitize.
 * @returns {string} - The sanitized HTML string.
 */
export function sanitizeHTML(html) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  tempDiv.classList.add('temp-div')

  function sanitizeNode(node) {
    const forbiddenTags = ['script', 'iframe', 'object', 'embed', 'noscript']
    const forbiddenAttributes = ['onload', 'onerror', 'onclick', 'onsubmit', 'onmouseover', 'onfocus', 'oninput']

    if (forbiddenTags.includes(node.nodeName.toLowerCase())) {
      node.remove()
      return
    }

    if (node.attributes) {
      for (let attr of Array.from(node.attributes)) {
        if (forbiddenAttributes.includes(attr.name.toLowerCase())) {
          node.removeAttribute(attr.name)
        } else if (['href', 'src'].includes(attr.name.toLowerCase())) {
          const attrValue = node.getAttribute(attr.name).toLowerCase()
          if (attrValue.startsWith('javascript:')) {
            node.removeAttribute(attr.name)
          } else if (attrValue.startsWith('data:') && !attrValue.startsWith('data:image/')) {
            node.removeAttribute(attr.name)
          }
        }
      }
    }

    if (node.childNodes) {
      for (let child of Array.from(node.childNodes)) {
        sanitizeNode(child)
      }
    }
  }

  if (tempDiv.childNodes) {
    for (let child of Array.from(tempDiv.childNodes)) {
      sanitizeNode(child)
    }
  }

  // JL fallback for pdfURL injection
  // if (format === 'Bedrock') {
  //   const placeHolder = '{{pdfURL}}'
  //   const pdfLinks = tempDiv.querySelectorAll('a.pdf-link')

  //   pdfLinks.forEach((pdfLink) => {
  //     if (pdfLink.getAttribute('href') === placeHolder) {
  //       pdfLink.setAttribute('href', pdfURL)
  //     }
  //   })
  // }

  return tempDiv.innerHTML
}
/**
 * Download file function
 * @param {string} downloadURL - The URL of the file to be downloaded
 * @param {string} fileName - The name of the file to be downloaded
 * @returns {Promise<void>} - A promise that resolves when the file has been downloaded
 */

export const downloadFile = async (downloadURL, fileName) => {
  try {
    const response = await fetch(downloadURL)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = Object.assign(document.createElement('a'), {
      href: url,
      download: fileName,
    })

    link.click()
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading document:', error)
  }
}
/**
 * Cleans nested objects by removing empty values
 * @param {Object} data - The object to be cleaned
 * @returns {Object} - The cleaned object
 */

export function cleanNestedObject(data) {
  const cleanedData = {}

  for (const key in data) {
    const value = data[key]

    if (typeof value === 'object' && value !== null) {
      const cleanedNestedData = cleanNestedObject(value)

      if (Object.keys(cleanedNestedData).length > 0) {
        cleanedData[key] = cleanedNestedData
      }
    } else if (value === true) {
      cleanedData[key] = true
    }
  }

  return cleanedData
}

/**
 * Toggles the values of the second object in the first object
 * @param {Object} firstObj - The object to be modified
 * @param {Object} secondObj - The object to be toggled
 * @returns {Object} - The modified first object
 */

export const toggleObjectValues = (firstObj, secondObj) => {
  const updateValues = (target, source) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (key in target) {
          if (typeof target[key] === 'object' && target[key] !== null && typeof source[key] === 'object') {
            updateValues(target[key], source[key])
          } else {
            target[key] = source[key]
          }
        }
      }
    }
  }

  const result = { ...firstObj }
  updateValues(result, secondObj)

  return result
}

/**
 * Return an array of n random unique values from the given array.
 *
 * @param {Array} arr - The source array.
 * @param {number} n   - The number of elements to choose.
 * @returns {Array}    - A new array containing n random unique elements.
 */
export const getRandomElements = (arr, n) => {
  const copy = [...arr]
  const result = []

  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * copy.length)
    result.push(copy[randomIndex])
    copy.splice(randomIndex, 1)
  }

  return result
}

/**
 * Recursively flattens a nested object into a single-level object.
 *
 * The function traverses each property of the input object. If a property's value is an object
 * (excluding arrays and null values), it recursively flattens it and prefixes its keys with the current path,
 * separated by the specified delimiter. Non-object values (including arrays and nulls) are added directly.
 *
 * @param {Object} obj - The source object to flatten. Can contain nested objects.
 * @param {string} [parentPath=''] - The base key path used during recursion. This should be left empty on initial call.
 * @param {string} [delimiter='.'] - The string used to delimit key levels in the flattened output.
 * @returns {Object} A new object where each key represents the full path to a value in the original object.
 *
 * @example
 * const nested = { a: { b: { c: 42 } }, d: 5 };
 * const flat = flattenObject(nested);
 * // flat is { "a.b.c": 42, "d": 5 }
 */
export const flattenObject = (obj, parentPath = '', delimiter = '.') => {
  let result = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Build the full key path
      const currentPath = parentPath ? `${parentPath}${delimiter}${key}` : key
      const value = obj[key]

      // Check if value is an object (but not an array or null) to recursively flatten it.
      if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        // Recursively flatten nested object and merge into the result.
        result = { ...result, ...flattenObject(value, currentPath, delimiter) }
      } else {
        // Assign non-object values directly.
        result[currentPath] = value
      }
    }
  }

  return result
}

/**
 * Converts a flattened object with delimited keys back into a nested object.
 *
 * This function splits each key in the flattened object using the specified delimiter to reconstruct
 * the nested structure. If intermediate keys do not exist or are not objects, they are created as empty objects.
 * The final key in each split is assigned the corresponding value from the flattened object.
 *
 * @param {Object} flatObj - The flattened object whose keys contain delimiter-separated paths.
 * @param {string} [delimiter='.'] - The delimiter used in the keys of the flattened object.
 * @returns {Object} A new nested object reconstructed from the flattened key paths.
 *
 * @example
 * const flat = { "a.b.c": 42, "d": 5 };
 * const nested = unflattenObject(flat);
 * // nested is { a: { b: { c: 42 } }, d: 5 }
 */
export const unflattenObject = (flatObj, delimiter = '.') => {
  const result = {}

  for (const flatKey in flatObj) {
    if (Object.prototype.hasOwnProperty.call(flatObj, flatKey)) {
      const value = flatObj[flatKey]
      // Split the key using the delimiter to get the hierarchy.
      const keys = flatKey.split(delimiter)
      let current = result

      // Traverse the keys, creating nested objects as needed.
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // If this is the last key, assign the value.
          current[key] = value
        } else {
          // If the key does not exist or is not an object, create a new object.
          if (!current[key] || typeof current[key] !== 'object') {
            current[key] = {}
          }
          // Move deeper into the nested structure.
          current = current[key]
        }
      })
    }
  }

  return result
}
