import { useState, useCallback, useContext } from 'react'
import { Button, useAPI, Search, ViewContext } from 'components/lib'
import { Link, useParams, useLocation } from 'react-router-dom'
import HtmlContent from '../htmlContent'
import classNames from './viewDocument.tailwind'
import { downloadFile } from 'utils'
import { MobileViewportWrapper } from '../AIChatWindow'
import { useWindowSize } from 'hooks/useWindowSize.hook'

const ViewDocument = () => {
  const searchResultURL = `/search/results`
  const { superType, documentType, documentId } = useParams()
  const [searchDocument, setSearchDocument] = useState('')
  const { data: documentFile, loading: isLoading } = useAPI(`/api/search/document/${documentType}/${documentId}`)
  const [contentType, setContentType] = useState(null)
  const [regexMode, setRegexMode] = useState(false)
  const viewContext = useContext(ViewContext)
  const location = useLocation()
  const { width: windowWidth } = useWindowSize()

  const params = new URLSearchParams(location.search)
  const fullTitle = params.get('fullTitle') || ''

  const handleContentTypeChange = useCallback((type) => {
    setContentType(type)
  }, [])

  const handleSearch = useCallback((search, regexMode) => {
    setSearchDocument(search)
    setRegexMode(regexMode)
  }, [])

  return (
    <div className={classNames.container}>
      <div className={`${windowWidth > 1280 ? '' : 'hidden'}`}>
        <MobileViewportWrapper />
      </div>
      <div className={`${windowWidth > 1280 ? 'ml-[395px]' : ''}`}>
        <div className={classNames.content}>
          <div className="flex flex-col lg:flex-row gap-4 justify-between items-center py-4">
            <div className={classNames.breadcrumbs}>
              <Link to={searchResultURL}>
                <span className={classNames.breadcrumbLink}>Search Result</span>
              </Link>
              {' > '} {superType} / {documentType} / {fullTitle}
            </div>

            {contentType === 'text/html' && (
              <div className={classNames.tools}>
                <Search
                  className={classNames.search.wrapper}
                  buttonClassName={classNames.search.button}
                  callback={handleSearch}
                  placeholder="Search In Document..."
                  inputClassName={classNames.search.input}
                />
                <Button
                  icon="copy"
                  color="#12367F"
                  size={16}
                  action={() => {
                    const endsWithBracketedContent = /\(([^)]*)\)$/
                    const match = fullTitle.match(endsWithBracketedContent)[1]
                    try {
                      new Date(match)
                      // If the last part of the title is a date, remove it before copying
                      navigator.clipboard.writeText(fullTitle.replace(/\([^)]*\)$/, '').trim())
                      viewContext.notification.show('Citation copied to clipboard', 'success', true)
                    } catch {
                      navigator.clipboard.writeText(fullTitle)
                    }
                  }}
                  className={classNames.button}
                />
                <Button
                  icon="download"
                  color="#12367F"
                  size={16}
                  action={() => downloadFile(documentFile?.pdf_url, fullTitle || 'document.pdf')}
                  className={classNames.button}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4 mt-4 bg-white px-5 md:px-20 pt-8 md:pt-10 rounded-lg shadow-sm">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <HtmlContent
                html_url={documentFile?.html_url}
                pdf_url={documentFile?.pdf_url}
                searchDocument={searchDocument}
                regexMode={regexMode}
                onContentTypeChange={handleContentTypeChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewDocument
