import { AuthContext } from 'components/lib'
import { CrossIcon } from 'icons'
import * as React from 'react'

const LCLSubscriberTermsConditionsAgreement = ({ handleClose }) => {
  const context = React.useContext(AuthContext)
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  // Common list styles
  const listStyles = {
    ordered: 'list-decimal pl-8 mb-4 space-y',
    subOrdered: 'list-[lower-alpha] pl-4 mb-4 space-y',
    subSubOrdered: 'list-[lower-roman] pl-4 mb-4 space-y',
  }

  // Common text styles
  const textStyles = {
    heading: 'text-primary-dark font-bold text-lg my-5',
    paragraph: 'mb-4 text-justify',
    bold: 'font-bold',
  }

  return (
    <div className=" bg-white text-sm">
      {/* Header */}
      <div className=" px-8 flex sticky top-0 py-4 mb-2 border-b items-center justify-between bg-white z-10">
        <h1 className="text-primary-dark font-bold text-xl">Subscriber Terms & Conditions Agreement</h1>
        <button className="!w-fit cursor-pointer " onClick={handleClose}>
          <CrossIcon className="cursor-pointer" />
        </button>
      </div>

      {/* Main Content */}
      <div className="px-8 max-h-[75vh] overflow-y-auto space-y-4 pb-8">
        <p className="mb-4">
          This Subscriber Terms and Conditions Agreement (hereinafter referred to as "Agreement") is entered on{' '}
          <span className="font-bold">{today}</span> between Law Cyborg Limited (hereinafter referred to as "LCL") and
          the person(s) you represent (hereinafter referred to as "Subscriber").
        </p>

        <p className="mb-4">LCL and the Subscriber are jointly referred to as "Parties" and individually as "Party".</p>

        <div>
          <p className="font-bold mb-2">Background:</p>
          <p className="mb-6">
            The Subscriber wishes to obtain access to the Law Cyborg Platform generally accessible via the domain name
            app.lawcyborg.com (hereinafter referred to as "Platform"). The Platform provides a variety of tools for use
            by professionals. LCL agrees to provide access to the Platform as well as ancillary technical support
            (hereinafter refer to as the "Services") as per the terms of this Agreement.
          </p>
        </div>

        <section>
          <h2 className={textStyles.heading}>1. Minimum term & renewal</h2>
          <ol className={listStyles.ordered}>
            <li>There is no minimum term for the Services.</li>
            <li>
              Subscriber agrees to have their Services automatically renewed upon the close of each subscription month.
              This includes renewal under new terms which the Subscriber has been notified of at least 10 working days
              in advance of renewal.
            </li>
            <li>
              The Subscriber may cancel the renewal of the Services at any time by either:
              <ol className={listStyles.subOrdered}>
                <li>Deleting their account; or</li>
                <li>
                  Giving LCL notice in the form of an email to support@lawcyborg.com and ceasing to use the Services
                  entirely.
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>2. Fees</h2>
          <ol className={listStyles.ordered}>
            <li>
              <span className="font-bold">Base Fee:</span> The Base Fee for use of the Platform is the total cost of the
              Plans and Practice Areas as selected by the Subscriber in administering its account on app.lawcyborg.com.
              <ol className={listStyles.subOrdered}>
                <li>
                  Payment of this fee provides:
                  <ol className={listStyles.subSubOrdered}>
                    <li>access to the Platform, Plan and Practice Area(s) purchased for one month; and</li>
                    <li>unlimited tokens; and</li>
                    <li>technical support.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="font-bold">Abuse fee:</span> Tokens used via botting or other abuse (in breach of clause
              2.1 of LCL's end-user terms of use) may be charged to the subscriber at a rate of $0.09 NZD plus GST per
              1,000 tokens.
            </li>
            <li>
              <span className="font-bold">Tokens used:</span> Any tokens sent to or returned by our service provider's
              generative pre-trained transformer API via the user, system or otherwise, are counted.
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>3. Payment terms</h2>
          <ol className={listStyles.ordered}>
            <li>The Subscriber shall pay LCL the fees described under "Fees" of this Agreement.</li>
            <li>The Base Fee must be paid before access to the Platform is granted each month.</li>
            <li>
              Any balance outstanding after 30 days of the invoice mailing date may be subject to a finance charge.
            </li>
            <li>LCL further reserves the right to suspend Services until outstanding balances are paid.</li>
            <li>
              Payment may be transacted through Stripe ("Facilitated Payment Mode", or "FPM"). If this is the case, the
              Subscriber agrees to the terms of use governing the FPM service.
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>4. Platform Access</h2>
          <ol className={listStyles.ordered}>
            <li>
              <span className="font-bold">Authorised Users:</span> A Subscriber may allow employees and/or independent
              contractors (hereinafter "Employees") to use the Platform on behalf of the Subscriber as authorised users.
            </li>
            <li>
              <span className="font-bold">User conditions:</span>
              <ol className={listStyles.subOrdered}>
                <li>
                  Conditional to access and use the Platform:
                  <ol className={listStyles.subSubOrdered}>
                    <li>
                      each user shall agree to abide by the terms of LCL's end-user terms of use which it may adopt from
                      time to time; and
                    </li>
                    <li>subscriptions cannot be shared or used by more than one Employee.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="font-bold">Account responsibility:</span>
              <ol className={listStyles.subOrdered}>
                <li>
                  The Subscriber shall be responsible for all usage of any account that the Subscriber is connected to,
                  whether or not the Subscriber has authorised the particular use or user, and regardless of the
                  Subscriber's knowledge of such use.
                </li>
                <li>
                  LCL shall not be responsible for any loss of information or use due to any irresponsible act such as
                  loss of the password by a user.
                </li>
              </ol>
            </li>
            <li>
              <span className="font-bold">Availability:</span> LCL will use reasonable efforts to ensure the Service is
              available on a 24 hour basis. However, it is possible that on occasion the Service may be unavailable to
              permit maintenance or other development activity, or in the event of Force Majeure. LCL will use
              reasonable efforts to notify the Subscriber advance details of any unavailability.
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>5. Confidentiality</h2>
          <p className={textStyles.paragraph}>
            All confidential information that is communicated to and obtained by LCL from the Subscriber in connection
            with performing the above-mentioned services shall be held by LCL in full trust. At no time LCL shall use
            any confidential information obtained through conducting this service contract either directly or
            indirectly, for personal benefit, or disclose or communicate such information in any manner, unless required
            by law.
          </p>
        </section>

        <section>
          <h2 className={textStyles.heading}>6. Reliance on information</h2>
          <p className={textStyles.paragraph}>
            LCL is an early-stage startup company. The accuracy of the information contained or output by the Platform
            is not guaranteed. The Subscriber is not permitted to solely rely on the accuracy of Services provided,
            including any information provided by the Platform's tools. LCL is not responsible for any loss caused by
            the reliance on information contained or output by the Platform.
          </p>
        </section>

        <section>
          <h2 className={textStyles.heading}>7. Proprietary Rights</h2>
          <ol className={listStyles.ordered}>
            <li>
              <span className="font-bold">Non-identifiable Subscriber Data:</span> The Subscriber hereby grants LCL a
              non-exclusive, transferable, sublicensable, worldwide, and royalty-free licence to use and otherwise
              exploit (i) subscriber data to provide the Services to the Subscriber hereunder and as necessary or useful
              to monitor and improve the Services, both during and after the subscription period. To avoid any doubt,
              the LCL shall use, reproduce and disclose Services-related information, data, and material that is
              anonymized, de-identified, or otherwise rendered not reasonably associated or linked to Subscriber, all of
              which information, data, and material, will be owned by LCL.
            </li>
            <li>
              <span className="font-bold">Identifiable Subscriber Data:</span> For marketing purposes, LCL may disclose
              that the Subscriber is party to an agreement with LCL.
            </li>
            <li>
              <span className="font-bold">Intellectual Property:</span> LCL shall retain all the subscription services,
              including all documentation, modifications, improvements, upgrades, derivative works, and all other
              Intellectual Property rights in connection with the Service, including the LCL's name, logos and
              trademarks reproduced through the Service.
            </li>
            <li>
              <span className="font-bold">User inputs:</span> Clause 7.3 does not apply to subscriber-provided content
              input to or regenerated by our system. LCL is granted a licence to process subscriber-provided content for
              the sole purpose of providing the Services.
            </li>
            <li>
              <span className="font-bold">Platform Outputs:</span> LCL grants the Subscriber a non-exclusive,
              transferable, sublicensable, worldwide, and royalty-free licence to content generated by the Platform, as
              a result of the Subscriber’s user inputs.
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>8. Security</h2>
          <ol className={listStyles.ordered}>
            <li>
              <span className="font-bold">Compliance with Notification Laws:</span> LCL shall comply with all applicable
              laws regarding the notification of individuals in the event of an unauthorised release of personally
              identifiable information and notification of other unauthorised data and information disclosures.
            </li>
            <li>
              <span className="font-bold">Procedure After Unauthorised Disclosure:</span> Within 10 working days of
              discovering any breach of LCL's security obligations or any other event requiring notification under
              applicable law, LCL shall notify the Subscriber, and any other individual's Law requires to be notified,
              of the breach or other events by telephone and e-mail.
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>9. Limitation of Liability</h2>
          <ol className={listStyles.ordered}>
            <li>
              In no event shall either Party:
              <ol className={listStyles.subOrdered}>
                <li>
                  Have liability arising out of or related to this Agreement, whether, in contract, tort or under any
                  other theory of liability exceed in the aggregate of the total fees paid or owed by the Subscriber
                  hereunder during the month immediately preceding the date of the event giving rise to the claim (such
                  amount being intended as a cumulative cap and not per incident).
                </li>
                <li>
                  Have any liability to the other for any lost profits or revenues or any indirect, incidental,
                  consequential, cover, special, exemplary, or punitive damages, however caused, whether, in contract,
                  tort, or under any other conditions of liability.
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <section>
          <h2 className={textStyles.heading}>10. Miscellaneous</h2>
          <ol className={listStyles.ordered}>
            <li>
              <span className="font-bold">Modification of end-user terms:</span> It is agreed and accepted that LCL may
              make reasonable updates to the Platform's end-user terms from time to time.
            </li>
            <li>
              <span className="font-bold">Consent to contact:</span> LCL is granted permission to contact the users
              connected to the Subscriber's account for marketing purposes or otherwise.
            </li>
            <li>
              <span className="font-bold">Ability to change LLMs:</span> The Subscriber agrees that LCL has the ability
              under this contract to change large language models (LLMs) utilised in their systems at LCL's sole
              discretion and understands that such change will affect the accuracy and quality of information output by
              the tools.
            </li>
            <li>
              <span className="font-bold">Assignability:</span> Neither party may assign this Agreement or the rights
              and obligations thereunder to any third party without the prior express written approval of the other
              Party which shall not be unreasonably withheld.
            </li>
            <li>
              <span className="font-bold">Non-exclusive:</span> LCL's provision of the Services to the Subscriber is
              non-exclusive. Nothing in the Agreement prevents LCL from providing the Services to any other person.
            </li>
            <li>
              <span className="font-bold">Rights of third parties:</span> No person other than the Subscriber and LCL
              has any right to benefit under, or to enforce, the Agreement.
            </li>
            <li>
              <span className="font-bold">Force Majeure:</span> Neither party shall be liable for any failure in
              performance of the obligation under this Agreement due to cause beyond that party's reasonable control
              (including and not limited to any pandemic, fire, strike, act or order of public authority, and other acts
              of God) during the pendency of such event.
            </li>
            <li>
              <span className="font-bold">Modification:</span> No modification of this Agreement shall be made unless in
              writing, signed by both parties.
            </li>
            <li>
              <span className="font-bold">Severability:</span> If any term, clause, or provision hereof is held invalid
              or unenforceable by a court of competent jurisdiction, all other terms will remain in full force and
              effect until the Agreement termination.
            </li>
            <li>
              <span className="font-bold">Governing Law and Jurisdiction:</span> This Agreement shall be governed by,
              and must be interpreted in accordance with, the laws of New Zealand.
            </li>
            <li>
              <span className="font-bold">Legal and Binding Agreement:</span> This Agreement is legal and binding
              between the Parties as stated above. The Parties each represent that they have the authority to enter into
              this Agreement.
            </li>
          </ol>
        </section>
      </div>
    </div>
  )
}

export default LCLSubscriberTermsConditionsAgreement
