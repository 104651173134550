import { useCallback, useMemo, useState } from 'react'
import FilterCategory from '../../components/filterStateful/filterCategory'
import { getDocumentWith, getPracticeAreaWith, uniqueByKey } from '@lawcyborg/packages'
import { useSearch } from 'views/searchAI/searchContext'
import { useRef } from 'react'
import { checkOptionsByCallback, findCheckedOptions } from 'components/filterStateful/utils'
import { Button } from 'components/lib'
import { DownIcon } from 'icons'
import { useWindowSize } from 'hooks/useWindowSize.hook'

/*
 * @param {Array} flatData - Array of practice areas
 * @param {Array} checkedValues - Array of values to be checked, if multiple checkboxes have the same value
 *                                all of them will be checked.
 * @returns {Object} - FilterCategory filter Object that can be used in the FilterCategory component.
 */
const namespacesToFilterCategory = (namespaces, isChecked) => {
  const result = {}
  const namespaceMap = {}

  for (const { namespace, name, grouping } of namespaces) {
    if (!grouping) continue
    const document = getDocumentWith('namespace', namespace)
    if (!document) continue
    const documentId = document.id

    const categories = grouping.split('/')
    let currentLevel = result
    let currentNamespace = namespaceMap

    categories.forEach((category) => {
      currentLevel[category] ??= {}
      currentNamespace[category] ??= {}

      currentLevel = currentLevel[category]
      currentNamespace = currentNamespace[category]
    })

    currentLevel[name] = { checked: isChecked ? isChecked(name, documentId) : true, value: documentId }
    currentNamespace[name] = documentId
  }
  return result
}

const categoriesToFilterCategory = (categories, isChecked) => {
  const result = {}
  for (const [category, issues] of Object.entries(categories)) {
    result[category] = {}
    issues.forEach(
      (issue) => (result[category][issue] = { checked: isChecked ? isChecked(category, issue) : true, value: issue })
    )
  }
  return result
}

const SearchFilters = ({ practiceArea = null }) => {
  const {
    setSelectedNamespaces,
    setSelectedCategories,
    selectedCategories,
    selectedNamespaces,
    documentTypeFilterCollapsed,
    categoryFilterCollapsed,
    setDocumentTypeFilterCollapsed,
    setCategoryFilterCollapsed,
  } = useSearch()
  const { width } = useWindowSize()
  const isMobile = width && width <= 767

  const styled = {
    wrapper: 'max-h-[40rem] min-w-[15rem] lg:min-w-[18rem] border p-2 lg:p-3 mt-3 rounded-lg overflow-y-auto',
  }
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState(practiceArea.map((pa) => pa.id) || [])
  const [quickFiltersCollapsed, setQuickFiltersCollapsed] = useState(true)
  const namespaceFilterRef = useRef()
  const categoryFilterRef = useRef()

  const initCategoryFilter = useMemo(() => {
    if (!practiceArea) return null
    if (selectedCategories) return selectedCategories
    const filter = {}
    for (const practice of practiceArea) {
      filter[practice.name] = categoriesToFilterCategory(practice.categories)
    }
    if (!selectedCategories) setSelectedCategories(filter)
    return filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initNamespaceFilter = useMemo(() => {
    if (!selectedPracticeAreas) return null
    const allNamespaces = selectedPracticeAreas.flatMap((practice) => getPracticeAreaWith('id', practice).namespaces)
    const unique = uniqueByKey(allNamespaces, 'namespace')
    const filter = namespacesToFilterCategory(unique)
    return filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onNamespacesChanged = useCallback(
    (filters) => {
      setSelectedNamespaces(filters)
    },
    [setSelectedNamespaces]
  )

  const onCategoriesChange = useCallback(
    (filters) => {
      const { nested } = findCheckedOptions(filters)
      const paNames = Object.keys(nested)
      const pAs = paNames.map((name) => getPracticeAreaWith('name', name))
      const paIds = pAs.map((pa) => pa.id)

      const allNamespaces = paIds.flatMap((practice) => getPracticeAreaWith('id', practice).namespaces)
      const unique = uniqueByKey(allNamespaces, 'namespace')
      const filter = namespacesToFilterCategory(unique)

      namespaceFilterRef.current.merge(filter)
      setSelectedPracticeAreas(paIds)
      setSelectedCategories(filters)
    },
    [setSelectedPracticeAreas, setSelectedCategories]
  )

  return (
    <div className=" lg:px-2">
      <div className={`pt-2`}>
        <div className={styled.wrapper}>
          <div className="lg:py-2">
            <h3
              onClick={() => setQuickFiltersCollapsed(!quickFiltersCollapsed)}
              className="uppercase relative font-semibold text-[10px] lg:text-xs text-primary-dark cursor-pointer"
            >
              Quick Filters
              <div
                onClick={() => setQuickFiltersCollapsed(!quickFiltersCollapsed)}
                className={`expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer ${
                  quickFiltersCollapsed ? '' : 'rotate-180'
                }`}
              >
                <DownIcon className="transition-transform duration-300" />
              </div>
            </h3>
            {!quickFiltersCollapsed && (
              <div className="mt-2 lg:mt-4 flex flex-col justify-between">
                <Button
                  small
                  text="Select All Documents"
                  color="white"
                  action={() => {
                    categoryFilterRef.current.reset(checkOptionsByCallback(selectedCategories, () => true))
                  }}
                  className="text-xs lg:text-sm"
                />
                <Button
                  small
                  text="Deselect All Documents"
                  color="white"
                  action={() => {
                    categoryFilterRef.current.reset(checkOptionsByCallback(selectedCategories, () => false))
                  }}
                  className="text-xs lg:text-sm"
                />
                <Button
                  small
                  text="Select Cases Only"
                  color="white"
                  action={() => {
                    namespaceFilterRef.current.reset(
                      checkOptionsByCallback(selectedNamespaces, ({ path }) => path.startsWith('Cases'))
                    )
                  }}
                  className="text-xs lg:text-sm"
                />
              </div>
            )}
          </div>
        </div>
        <div className={styled.wrapper}>
          <FilterCategory
            ref={namespaceFilterRef}
            key={'Document Types'}
            name={'Document Types'}
            initCollapsed={documentTypeFilterCollapsed}
            onCollapsedChange={setDocumentTypeFilterCollapsed}
            initFilters={initNamespaceFilter}
            onFilterChange={onNamespacesChanged}
          />
        </div>

        <div className={styled.wrapper}>
          <FilterCategory
            key={'Categories'}
            name={'Categories'}
            ref={categoryFilterRef}
            initCollapsed={categoryFilterCollapsed}
            onCollapsedChange={setCategoryFilterCollapsed}
            initFilters={initCategoryFilter}
            onFilterChange={onCategoriesChange}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchFilters
