import { useEffect, useState, useCallback, memo, useMemo } from 'react'
import { Button, Drawer, TagBadge, useAPI } from 'components/lib'
import { AccordionItem } from 'components/accordion'
import { AddIcon, MinusIcon } from 'icons'
import useCallAPI from 'components/hooks/callApi'
import { useSearchParams } from 'react-router-dom'
import { currentInfoModulesSet } from 'views/dashboard/definitions'
import { cn, GST } from 'utils'
import { moduleIds } from '@lawcyborg/packages'
import { useAuth } from 'app/auth'

const SummaryRow = memo(({ label, value, className = 'text-sm', dataTestId = '' }) => (
  <div className={`flex justify-between ${className}`}>
    <p>{label}</p>
    <p {...(dataTestId ? { 'data-testid': dataTestId } : {})}>{value}</p>
  </div>
))

export const OrderSummary = ({ totalItems, totalValue, className, gst, orderTitle = 'Summary' }) => {
  return (
    <div className={cn(className, 'border-t pt-4 mt-4')}>
      <h3 className="font-bold text-gray-700 mb-2">{orderTitle}</h3>
      <SummaryRow label="Total Items" value={totalItems} />
      <SummaryRow dataTestId={'excludingGSTPrice'} label="Total Excl. GST" value={`$${totalValue.toFixed(2)}`} />
      <SummaryRow dataTestId={'totalGSTPrice'} label="Total GST" value={`$${gst.toFixed(2)}`} />
      <SummaryRow
        dataTestId={'totalPrice'}
        label="Total Inc. GST"
        value={`$${(totalValue + gst).toFixed(2)}`}
        className="font-semibold text-lg"
      />
    </div>
  )
}

const ProductItem = memo(({ product, quantity, onQuantityChange, className }) => (
  <div
    data-testid="plansList"
    className={cn('flex justify-between items-center border-b py-1 last:border-b-0 last:mb-0', className)}
  >
    <div className="relative">
      {product.name} {product?.earlyAccess && <TagBadge />}
      <p className="text-sm text-gray-500">${product.price_per_unit}</p>
    </div>
    <div className="flex items-center gap-2">
      <input
        type="number"
        data-testid="quantityInput"
        className="px-2 py-1 rounded border w-[3rem] text-sm"
        value={quantity}
        onChange={(e) => onQuantityChange(product.name, e.target.value)}
      />
      <button
        data-testid="plansAdd"
        className="px-3 py-1 h-7 border rounded text-sm bg-[#F6F6F7] hover:bg-gray-300 cursor-pointer"
        onClick={() => onQuantityChange(product.name, quantity + 1)}
      >
        <AddIcon height={10} />
      </button>
      <button
        data-testid="plansMinus"
        className="px-3 py-1 bg-[#F6F6F7] h-7 border rounded text-sm hover:bg-gray-300 cursor-pointer disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-400 disabled:opacity-75 disabled:cursor-default"
        onClick={() => onQuantityChange(product.name, quantity - 1)}
        disabled={product?.assigned_license >= quantity || quantity === 0}
      >
        <MinusIcon width={10} />
      </button>
    </div>
  </div>
))

const DrawerContent = ({ handleOpenPurchaseProducts, onPurchase }) => {
  const { accounts } = useAuth()
  const [purchaseProducts, _, loading] = useCallAPI({
    url: `/api/buy-product-license`,
    method: 'POST',
  })
  const productResponse = useAPI('/api/products-list')
  const { data } = productResponse
  const availableInformationModules = data?.informationModules?.filter((module) => {
    return currentInfoModulesSet.has(module.module_id)
  })

  const allProducts = useMemo(
    () => (data ? [...data?.products, ...availableInformationModules] : []),
    [data, availableInformationModules]
  )

  // State
  const [productQuantities, setProductQuantities] = useState({})

  // Effects
  useEffect(() => {
    if (allProducts?.length === 0) return
    const tempProducts = allProducts.reduce((acc, product) => {
      acc[product.name] = product?.purchased_quantity || 0
      return acc
    }, {})
    setProductQuantities(tempProducts)
  }, [productResponse])

  // Derived values
  const isCartChanged = useMemo(() => {
    return allProducts.some((product) => productQuantities[product.name] !== (product.purchased_quantity || 0))
  }, [allProducts, productQuantities])

  const totalItems = Object.values(productQuantities).reduce((sum, quantity) => sum + quantity, 0)
  const totalValue = allProducts.reduce((sum, product) => {
    return sum + parseFloat(product.price_per_unit) * productQuantities[product.name]
  }, 0)
  const gstRate = GST[accounts[0].country]
  const gst = totalValue > 0 ? totalValue * gstRate : 0

  // Handlers
  const handleQuantityChange = useCallback((name, newValue) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [name]: Math.max(0, parseInt(newValue) || 0),
    }))
  }, [])

  const handleContinue = async () => {
    const selectedProducts = allProducts
      .filter((product) => productQuantities[product.name] !== product.purchased_quantity)
      .map((product) => ({
        plan: product.price_id,
        quantity: productQuantities[product.name],
        module_id: product.module_id,
      }))
    if (selectedProducts.length === 0) return
    const response = await purchaseProducts({
      requestData: {
        products: selectedProducts,
      },
    })
    if (response && !response.isAxiosError) {
      handleOpenPurchaseProducts(false)
      onPurchase()
    }
  }

  if (productResponse.loading) return <>Loading...</>

  return (
    <>
      <h3 className="font-medium text-brand-500 pb-2">Plan Licences</h3>
      {!productResponse.loading && (
        <div className="h-[calc(100dvh-20rem)] overflow-y-auto">
          {data?.products?.map((product) => (
            <ProductItem
              key={product.name}
              product={{ ...product, earlyAccess: product.module_id === moduleIds.ELITE_PLAN }}
              className="mb-0 pb-2"
              quantity={productQuantities[product.name]}
              onQuantityChange={handleQuantityChange}
            />
          ))}
          <AccordionItem className="!px-0" title={'Practice Area Licences'} dataTestId={'showPracticeAreas'}>
            <div className="max-h-[calc(100vh-30rem)] overflow-y-auto">
              {availableInformationModules?.length &&
                availableInformationModules?.map((product) => (
                  <ProductItem
                    key={product.name}
                    product={{ ...product, earlyAccess: product.module_id !== moduleIds.TAX }}
                    className="mb-2 pb-2"
                    quantity={productQuantities[product.name]}
                    onQuantityChange={handleQuantityChange}
                  />
                ))}
            </div>
          </AccordionItem>
        </div>
      )}
      <OrderSummary totalItems={totalItems} totalValue={totalValue} gst={gst} orderTitle="Monthly Plan Summary" />
      {/* TODO: implement a modal to show confirm msg */}
      <Button
        small
        data-testid="purchaseProductButton"
        disabled={!isCartChanged}
        fullWidth
        loading={loading}
        className="mt-5"
        text="Continue"
        action={handleContinue}
        color="brand3"
      />
    </>
  )
}

const PurchaseProducts = ({ onPurchase }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isPurchaseProductOpen = searchParams.get('purchaseProducts') === 'true'
  const handleOpenPurchaseProducts = (isOpen) => {
    if (searchParams.get('purchaseProducts') === isOpen) return
    const params = new URLSearchParams(window.location.search)
    isOpen ? params.set('purchaseProducts', isOpen) : params.delete('purchaseProducts')
    setSearchParams(params)
  }

  const triggerButton = (
    <div className="flex gap-2 items-center" data-testid="productsDiv">
      <Button small data-testid="assignLicences" text="Assign Licences" goto={'/account/users'} color="brand3" />
      <Button
        small
        data-testid="purchaseLicences"
        text="Purchase Licences"
        action={() => handleOpenPurchaseProducts(true)}
        color="brand3"
      />
    </div>
  )

  return (
    <Drawer
      open={isPurchaseProductOpen}
      onOpenChange={handleOpenPurchaseProducts}
      triggerButton={triggerButton}
      title="Purchase Licences"
      width="28rem"
    >
      <DrawerContent handleOpenPurchaseProducts={handleOpenPurchaseProducts} onPurchase={onPurchase} />
    </Drawer>
  )
}

export default PurchaseProducts
