import { chatTypeIds } from '@lawcyborg/packages'
import { withWebSocket } from 'app/websocket'
import { AuthContext, Header } from 'components/lib'
import { useContext, useState, useMemo, useEffect } from 'react'
import { AIAuto } from 'views/dashboard/aiAuto'
import { AiGeneral } from 'views/dashboard/aiGeneral'
import { LawCyborg } from 'views/dashboard/lawCyborg'
import '../../../src/components/nav/app/app.scss'
import { AIOptions } from 'routes/app'
import { SettingsContext } from 'app/settings'

export const MobileViewportWrapper = () => {
  const auth = useContext(AuthContext)
  const { availablePracticeAreas } = auth
  const paCount = availablePracticeAreas.length
  const settingsContext = useContext(SettingsContext)
  const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false

  const [practiceArea, setPracticeArea] = useState(
    paCount === 0 ? chatTypeIds.GENERAL : paCount > 1 ? chatTypeIds.AUTO : availablePracticeAreas[0]
  )

  useEffect(() => {
    if (availablePracticeAreas.length === 0) setPracticeArea(chatTypeIds.GENERAL)
    else if (availablePracticeAreas.length > 1) setPracticeArea(chatTypeIds.AUTO)
    else setPracticeArea(availablePracticeAreas[0])
  }, [availablePracticeAreas])

  const modifiedOptions = useMemo(
    () =>
      AIOptions.map(({ link, ...rest }) => ({
        ...rest,
        onClick: (item) => setPracticeArea(item.id),
      })),
    [setPracticeArea]
  )

  const renderChatComponent = () => {
    if (practiceArea === chatTypeIds.AUTO) return withWebSocket(AIAuto)({ isChatWindow: true })
    else if (practiceArea === chatTypeIds.GENERAL) return withWebSocket(AiGeneral)({ isChatWindow: true })
    else return withWebSocket(LawCyborg)({ isChatWindow: true, practiceArea })
  }

  return (
    <>
      {shouldHideAiGeneral && paCount === 0 ? (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
          <p className="text-lg text-gray-600 font-medium">You do not have access to any chats.</p>
        </div>
      ) : (
        <div className="w-[395px] h-[100vh] top-0 -ml-7 border-r fixed">
          <Header
            title="AI"
            options={modifiedOptions}
            selectedPracticeArea={practiceArea}
            onClickPracticeArea={(pa) => setPracticeArea(pa)}
          />
          {renderChatComponent()}
        </div>
      )}
    </>
  )
}
