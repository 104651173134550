import { useEffect, useState, useRef } from 'react'
import './checkbox.module.scss'
import { DownIcon } from 'icons'
import { useWindowSize } from 'hooks/useWindowSize.hook'

export default function Checkbox({
  className,
  checkBoxClassName,
  item: { value: valueProp, label: labelProp, checked: checkedProp, indeterminate: indeterminateProp, disabled },
  onChange: onChangeProp,
  isGroup,
  handleExpand,
  ...props
}) {
  const [checked, setChecked] = useState(checkedProp)
  const [indeterminate, setIndeterminate] = useState(indeterminateProp)
  const checkboxRef = useRef(null)
  const uniqueId = useRef(`checkbox-${Math.random().toString(36).slice(2, 9)}`)
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth && windowWidth <= 767

  useEffect(() => {
    setChecked(checkedProp)
    setIndeterminate(indeterminateProp)
  }, [checkedProp, indeterminateProp])

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  const onChange = (e) => {
    setChecked(e.target.checked)
    setIndeterminate(false)
    if (onChangeProp) onChangeProp(e)
  }

  const handleExpandClick = (e) => {
    const expandEl = e.currentTarget
    const currentState = expandEl.getAttribute('data-expand') === 'true'
    expandEl.setAttribute('data-expand', (!currentState).toString())

    if (handleExpand) {
      handleExpand(!currentState)
    }
  }

  return (
    <div className={`relative flex items-center space-x-2 w-full py-1 lg:py-2 px-1 ${checkBoxClassName}`}>
      <input
        {...props}
        ref={checkboxRef}
        onChange={onChange}
        value={valueProp}
        id={uniqueId.current}
        checked={checked}
        disabled={disabled}
        className={
          className +
          ' focus:border-pink rounded border-gray-300 appearance-none relative peer shrink-0 w-[18px] h-[18px] border-2 bg-[#CBD5E1] checked:bg-brand-500 checked:border-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:brand-400 hover:ring-2 hover:brand-400 disabled:border-[#C6C6C6] disabled:bg-[#C6C6C6]' +
          (indeterminate ? ' indeterminate:bg-blue-300 indeterminate:border-0' : '')
        }
        type="checkbox"
      />
      {Boolean(labelProp) && (
        <label htmlFor={uniqueId.current} className="text-xs lg:text-sm text-[#475569">
          {labelProp}
        </label>
      )}
      {isGroup && (
        <div
          onClick={handleExpandClick}
          data-expand="false"
          className="expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer"
        >
          <DownIcon className="transition-transform duration-300" />
        </div>
      )}
    </div>
  )
}
