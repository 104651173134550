/**
 * Traverses a nested filter structure to extract checked filter options
 *
 * This function processes a hierarchical filter structure (from FilterCategory
 * components) and identifies all checked options. It produces two output formats:
 *
 * 1. A flattened array of all checked options with their labels and values
 * 2. A nested object that preserves the hierarchical structure but only includes checked options
 *
 * @param {Object} filters - A nested filter object where each leaf node has 'checked' and 'value' properties
 * @example
 *   {
 *     option1: { checked: true, value: 'option1-value' },
 *     group1: {
 *       nestedOption1: { checked: false, value: 'nested1-value' },
 *       nestedOption2: { checked: true, value: 'nested2-value' }
 *     }
 *   }
 *
 * @returns {Object} Object containing two properties:
 *   - flat: Array of objects with { label, value } for all checked options
 *   - nested: Object preserving the original hierarchy with only checked options
 *
 * @example
 * // Returns:
 * {
 *   flat: [
 *     { label: 'option1', value: 'option1-value' },
 *     { label: 'nestedOption2', value: 'nested2-value' }
 *   ],
 *   nested: {
 *     option1: 'option1-value',
 *     group1: {
 *       nestedOption2: 'nested2-value'
 *     }
 *   }
 * }
 */
export const findCheckedOptions = (filters) => {
  const isLeaf = (obj) => obj.hasOwnProperty('checked') && obj.hasOwnProperty('value')
  const flat = []
  const nested = {}
  const findCheckedOptions = (filters, obj) => {
    for (const key in filters) {
      if (filters[key].checked) {
        flat.push({ label: key, value: filters[key].value })
        obj[key] = filters[key].value
      } else if (isLeaf(filters[key]) && !filters[key].checked) {
        delete obj[key]
        continue
      } else if (!isLeaf(filters[key])) {
        obj[key] = {}
        findCheckedOptions(filters[key], obj[key])
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key]
        }
      }
    }
  }
  findCheckedOptions(filters, nested)

  return { flat, nested }
}

/**
 * This function processes a hierarchical filter structure (from FilterCategory
 * components) and applies a callback to each leaf node to determine whether it should be checked.
 *
 * @param {Object} filters - A nested filter object. Leaf nodes have properties 'checked' and 'value'.
 * @param {Function} callback - A function called for each leaf filter option. It is invoked with an object:
 * @example
 *   {
 *     option: { checked: boolean, value: any }, // The current filter option
 *     path: string,                            // Hierarchical path to the option (e.g., "group1/nestedOption2")
 *     label: string                            // The key/label of the option
 *   }
 *   // The callback should return a boolean indicating whether the option should be checked.
 *
 * @returns {Object} A new filter object with updated 'checked' states based on the callback.
 *
 * @example
 * const filters = {
 *   option1: { checked: false, value: 'option1-value' },
 *   group1: {
 *     nestedOption1: { checked: false, value: 'nested1-value' },
 *     nestedOption2: { checked: false, value: 'nested2-value' }
 *   }
 * };
 *
 * // Callback that checks options if the label contains "1"
 * const callback = ({ option, path, label }) => label.includes('1');
 *
 * const updatedFilters = checkOptionsByCallback(filters, callback);
 *
 * @example
 * // updatedFilters will be:
 * {
 *   option1: { checked: true, value: 'option1-value' },
 *   group1: {
 *     nestedOption1: { checked: true, value: 'nested1-value' },
 *     nestedOption2: { checked: false, value: 'nested2-value' }
 *   }
 * }
 */
export const checkOptionsByCallback = (filters, callback) => {
  const isLeaf = (obj) => obj.hasOwnProperty('checked') && obj.hasOwnProperty('value')
  const checkOptionsByCallback = (filters, path) => {
    for (const key in filters) {
      if (isLeaf(filters[key])) {
        filters[key].checked = callback({ option: filters[key], path, label: key })
      } else {
        checkOptionsByCallback(filters[key], path ? `${path}/${key}` : key)
      }
    }
  }
  checkOptionsByCallback(filters, '')
  return { ...filters }
}
