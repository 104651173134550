/***
 *
 *   TAB VIEW
 *   Create a tabbed view by wrapping each view element in a <TabView> component.
 *
 *   PROPS
 *   name: specify a name if you want to persist the active tab state
 *   labels: array of strings for the tab labels
 *
 **********/

import { useState, cloneElement } from 'react'
import { ClassHelper } from 'components/lib'
import Style from './tabview.tailwind.js'

export function TabView(props) {
  const numTabs = props.children.length
  let initialIndex = 0

  if (props.name) {
    const savedViewIndex = localStorage.getItem(props.name + 'Tabs')
    if (savedViewIndex !== null) {
      const index = parseInt(savedViewIndex, 10)
      if (!isNaN(index) && index >= 0 && index < numTabs) {
        initialIndex = index
      }
    } else if (props.initialView !== undefined) {
      initialIndex = props.initialView
    }
  } else if (props.initialView !== undefined) {
    initialIndex = props.initialView
  }

  const [activeTabIndex, setActiveTabIndex] = useState(initialIndex)

  function switchTab(index) {
    setActiveTabIndex(index)
    if (props.name) {
      localStorage.setItem(props.name + 'Tabs', index.toString())
    }
  }

  return (
    <div className="bg-white mt-3 rounded-lg sticky top-100">
      <nav className={Style.tabs}>
        {props.labels?.map((label, index) =>
          label ? (
            <Tab dataTestId={label} key={index} active={activeTabIndex === index} click={() => switchTab(index)}>
              {label}
            </Tab>
          ) : null
        )}
      </nav>

      {props.children?.map((view, index) =>
        view && activeTabIndex === index ? cloneElement(view, { key: index }) : null
      )}
    </div>
  )
}

function Tab(props) {
  const buttonStyle = ClassHelper(Style, {
    button: true,
    buttonActive: props.active,
    className: props.className,
  })
  const buttonActiveBarStyle = ClassHelper(Style, {
    buttonActiveBar: true,
    inActiveBar: !props.active,
    activeBar: props.active,
  })
  return (
    <button
      {...(props.dataTestId ? { 'data-testid': props.dataTestId } : {})}
      className={buttonStyle}
      onClick={props.click}
    >
      {props.children}
      <div className={buttonActiveBarStyle} />
    </button>
  )
}
