import React, { useState, useRef, useEffect } from 'react'
import Style from './accordion.tailwind.js'
import { DownIcon } from 'icons/index.js'

export const AccordionItem = ({ title, children, open = false, className = '', dataTestId = '', ...props }) => {
  const [isOpen, setIsOpen] = useState(open)
  const [height, setHeight] = useState('0px')
  const contentRef = useRef(null)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px')
  }, [isOpen, contentRef, setHeight])

  return (
    <div className={Style.item + ' ' + className} {...props}>
      <button className={Style.button} onClick={toggleOpen}>
        <span className={Style.title}>{title}</span>
        <DownIcon
          {...(dataTestId ? { 'data-testid': dataTestId } : {})}
          className={`${Style.icon} ${isOpen ? Style.iconOpen : ''}`}
        />
      </button>
      <div
        ref={contentRef}
        className={`${Style.content} ${isOpen ? Style.contentVisible : Style.contentHidden}`}
        style={{ maxHeight: height }}
      >
        {children}
      </div>
    </div>
  )
}

const Accordion = ({ items, className = '', itemsClassName = '' }) => {
  return (
    <div className={`${Style.base} ${className}`}>
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} className={itemsClassName}>
          {item.content}
        </AccordionItem>
      ))}
    </div>
  )
}

export default Accordion
