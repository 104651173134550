import { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import {
  ChatInputForm,
  ConversationDisplay,
  ChatDisclaimer,
  useConversation,
  useConversationAutoScroll,
} from 'components/lib'

import { disclaimers } from './definitions'
import ChatHistory from 'components/ai/chatHistory'
import TaxAiChatSetting from 'components/ai/taxAiChatSetting'
import { chatTypeIds, practiceAreas } from '@lawcyborg/packages'

/*
 * @param {Array} flatData - Array of practice areas
 * @param {Array} checkedValues - Array of values to be checked, if multiple checkboxes have the same value
 *                                all of them will be checked.
 * @returns {Object} - FilterCategory filter Object that can be used in the FilterCategory component.
 */
export const practiceAreaNamespacesToFilterCategory = (flatData, checkedValues) => {
  const result = {}
  const namespaceMap = {}

  for (const { namespace, name, grouping } of flatData) {
    if (!grouping) continue

    const categories = grouping.split('/')
    let currentLevel = result
    let currentNamespace = namespaceMap

    categories.forEach((category) => {
      currentLevel[category] ??= {}
      currentNamespace[category] ??= {}

      currentLevel = currentLevel[category]
      currentNamespace = currentNamespace[category]
    })

    currentLevel[name] = { checked: checkedValues ? checkedValues.includes(namespace) : true, value: namespace }
    currentNamespace[name] = namespace
  }
  return result
}

export const useChatContext = ({ setAuto, setPracticeArea, setSelectedNamespaces, setChatType, updateUrl }) => {
  const [autoState, setAutoState] = useState(true)
  const [practiceAreaState, setPracticeAreaState] = useState(chatTypeIds.GENERAL)
  const [namespacesState, setNamespacesState] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const updateNamespaces = useCallback(
    (namespaces) => {
      setNamespacesState(namespaces)
      if (updateUrl) {
        const params = new URLSearchParams(window.location.search)
        params.delete('namespaces')
        namespaces.forEach((ns) => params.append('namespaces', ns))
        setSearchParams(params)
      }
      setSelectedNamespaces(namespaces)
    },
    [setSelectedNamespaces]
  )
  const updateAutoMode = useCallback(
    (auto) => {
      setAutoState(auto)
      if (updateUrl) {
        const params = new URLSearchParams(window.location.search)
        params.delete('auto')
        params.set('auto', auto)
        setSearchParams(params)
      }
      setAuto(auto)
    },
    [setAuto]
  )
  const updatePracticeArea = (practiceArea) => {
    setPracticeAreaState(practiceArea)
    if (updateUrl) {
      const params = new URLSearchParams(window.location.search)
      params.delete('practiceArea')
      params.set('practiceArea', practiceArea)
      setSearchParams(params)
    }
    setChatType('aiTaxNew')
    setPracticeArea(practiceArea)
  }
  useLayoutEffect(() => {
    if (updateUrl) {
      const namespacesFromParams = searchParams.getAll('namespaces')
      const autoFromParams = searchParams.get('auto')
      const practiceAreaFromParams = searchParams.get('practice_area')
      if (autoFromParams && autoFromParams !== `${autoState}`) {
        updateAutoMode(autoFromParams === 'true')
      }
      if (namespacesFromParams.length > 0 && JSON.stringify(namespacesFromParams) !== JSON.stringify(namespacesState)) {
        setSelectedNamespaces(namespacesFromParams)
      }
      if (practiceAreaFromParams) {
        let paId = Object.values(practiceAreas).find((pa) => pa.slug === practiceAreaFromParams)?.id
        if (paId === practiceAreaState) return
        setChatType('aiTaxNew')
        setPracticeArea(paId)
      }
    }
  }, [location])
  return {
    namespacesState, //added states for future reference
    autoState,
    practiceAreaState,
    updateNamespaces,
    updateAutoMode,
    updatePracticeArea,
  }
}

export function LawCyborg(props) {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    mode,
    setMode,
    branch,
    swapBranch,
    loadConversation,
    conversationId,
    stopStreaming,
    loadConversations,
    historyCached,
    selectedNamespaces,
    setSelectedNamespaces,
    setAuto,
    auto,
    setPracticeArea,
    practiceArea,
    loadingConversation,
    setChatType,
  } = useConversation({ practiceArea: chatTypeIds.GENERAL, chatType: 'aiTaxNew', usesChatHistory: true })
  const { updateAutoMode, updateNamespaces, updatePracticeArea } = useChatContext({
    setAuto,
    setPracticeArea,
    setSelectedNamespaces,
    setChatType,
    updateUrl: !props.isChatWindow,
  })

  //TODO:  for future we can covert useConversation to a context and use it here as glabal state
  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>
  const [filters, setFilters] = useState()

  // FIXME:  need to refactor this to useChatContext

  useEffect(() => {
    if (practiceArea && practiceArea !== chatTypeIds.GENERAL) {
      const practiceAreaObj = Object.values(practiceAreas).find((pa) => pa.id === practiceArea)
      setFilters(
        {
          [practiceAreaObj.name]: practiceAreaNamespacesToFilterCategory(
            practiceAreaObj?.namespaces,
            selectedNamespaces
          ),
        } || {}
      )
    }
  }, [practiceArea, selectedNamespaces])

  useEffect(() => {
    if (props.practiceArea && props.isChatWindow) {
      updatePracticeArea(props.practiceArea)
    }
  }, [props.practiceArea, props.isChatWindow, updatePracticeArea])
  // LV LCEN-149 20231113 We allow for a total of 10 questions
  // in this chat which is 10 user messages plus the previous 9 responses (19 total).
  // Note, even though the max is 19, this does not block the final response so there
  // will still be 20 messages after the 10th question.
  const MAX_CHAT_LENGTH = 19
  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative overflow-x-hidden">
      {practiceArea && practiceArea !== chatTypeIds.GENERAL && (
        <TaxAiChatSetting
          mode={mode}
          setMode={setMode}
          filters={filters}
          onSelectionChange={updateNamespaces}
          onAutoChange={updateAutoMode}
          isChatWindow={props.isChatWindow}
        />
      )}

      <ChatHistory
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f3f8fa'}
        onOpen={loadConversations}
        loading={!historyCached}
        enableRefresh={exchanges.length}
        isStreaming={!loading && !canSend}
        isChatWindow={props.isChatWindow}
      />

      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%] no-scrollbar" id="chat">
        {!(loadingConversation || exchanges.length) && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={disclaimers.nz[practiceArea] || disclaimers.nz[chatTypeIds.TAX]}
            isChatWindow={props.isChatWindow}
          />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          loadingConversation={loadingConversation}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
          isChatWindow={props.isChatWindow}
        />
      </div>

      <div
        className={`${props.isChatWindow ? '' : 'lg:w-[67%] md:w-[90%]'} self-center relative webfill flex items-center justify-center `}
      >
        {/* <RefreshButton enabled={exchanges.length} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          inputReadOnly={exchanges.length >= MAX_CHAT_LENGTH}
          placeholderText={
            exchanges.length >= MAX_CHAT_LENGTH
              ? 'A maximum of 10 questions can be asked in this chat. Please refresh to start a new conversation.'
              : 'Ask a question...'
          }
          isChatWindow={props.isChatWindow}
        />
      </div>
    </div>
  )
}
