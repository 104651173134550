import { useEffect, useState } from 'react'
import FilterOption from './filterOption'
import { DownIcon } from 'icons'
import { forwardRef, useImperativeHandle } from 'react'
import { flattenObject, unflattenObject } from '../../utils/helper.js'
import { useWindowSize } from 'hooks/useWindowSize.hook'

/**
 * FilterCategory Component
 *
 * A collapsible React component that manages and displays a nested set of filter options.
 * Each filter is rendered as a checkbox and may contain nested child options.
 *
 * The component internally manages its own state for both the filter options {initFilters} and
 * the expanded/collapsed view {initCollapsed}. This means that while the parent provides the initial
 * state via props, subsequent changes to these props will not update the component.
 * Instead, any changes made through user interaction are communicated back via callbacks.
 *
 * @param {string} name - The label for the filter category. Clicking the label toggles the collapse state.
 * @param {Object} initFilters - A nested object defining the filter structure. Each filter option can be:
 *    - A leaf node with:
 *         - `checked` {boolean}: Indicates if the checkbox is selected.
 *         - `value` {any}: The value associated with the checkbox.
 *    - A nested object containing further filter options.
 * @param {function} onFilterChange - Callback function triggered whenever the filter state changes.
 *        It receives two arguments: the updated filters object and the category name.
 * @param {boolean} [showName=true] - Determines whether the category name should be displayed.
 * @param {boolean} [initCollapsed=true] - Sets the initial collapsed state; true means the filters are hidden.
 * @param {function} [onCollapsedChange] - Optional callback function that is called when the collapse state changes.
 *        It receives the new collapse state (true if collapsed, false if expanded) as an argument.
 *
 * @example
 * // Example nested filter structure:
 * const filters = {
 *   option1: { checked: true, value: 'option 1' },
 *   option2: {
 *     suboption1: { checked: false, value: 'suboption 1' },
 *     suboption2: { checked: true, value: 'suboption 2' }
 *   }
 * };
 *
 * <FilterCategory
 *   name="Example Category"
 *   initFilters={filters}
 *   onFilterChange={(updatedFilters, categoryName) => console.log(updatedFilters, categoryName)}
 *   showName={true}
 *   initCollapsed={false}
 *   onCollapsedChange={(newState) => console.log("Collapsed:", newState)}
 * />
 */

const FilterCategory = forwardRef(
  (
    {
      name,
      initFilters: initFilterProp,
      onFilterChange,
      showName = true,
      initCollapsed = true,
      onCollapsedChange: onCollapsedChangeProp,
    },
    ref
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(initCollapsed)
    const [filters, setFilters] = useState(initFilterProp)
    const { width } = useWindowSize()
    const isMobile = width && width <= 767
    useImperativeHandle(ref, () => ({
      reset: (newFilter) => {
        setFilters(JSON.parse(JSON.stringify(newFilter)))
      },
      merge: (newFilter) => {
        const oldFilters = flattenObject(filters)
        const newFilters = flattenObject(newFilter)

        for (const key in newFilters) {
          if (oldFilters.hasOwnProperty(key)) {
            newFilters[key] = oldFilters[key]
          }
        }
        const updatedFilters = unflattenObject(newFilters)
        setFilters(updatedFilters)
      },
    }))

    const updateNestedState = (state, path, value) => {
      if (path.length === 1) {
        return { ...state, [path[0]]: value }
      } else {
        return {
          ...state,
          [path[0]]: updateNestedState(state[path[0]] || {}, path.slice(1), value),
        }
      }
    }

    const handleNamespaceChange = (optionPath, isChecked) => {
      setFilters((prevFilters) => updateNestedState(prevFilters, [...optionPath], isChecked))
    }

    const onCollapsedChange = () => {
      setIsCollapsed(!isCollapsed)
      if (onCollapsedChangeProp) onCollapsedChangeProp(!isCollapsed)
    }

    useEffect(() => {
      if (onFilterChange) onFilterChange(filters, name)
    }, [filters, onFilterChange, name])

    return (
      <div className=" lg:py-2">
        {name && showName && (
          <h3
            onClick={onCollapsedChange}
            className="uppercase relative font-semibold text-[10px] lg:text-xs text-primary-dark"
          >
            {name}
            <div
              onClick={onCollapsedChange}
              data-expand="false"
              className={`expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer ${isCollapsed ? '' : 'rotate-180'}`}
            >
              <DownIcon className="transition-transform duration-300" />
            </div>
          </h3>
        )}
        {(!name || !isCollapsed || (name && !showName)) && (
          <div className="mt-2 mb-[-0.5rem]">
            {Object.keys(filters).map((option, index) => (
              <FilterOption
                key={option}
                option={option}
                value={filters[option]}
                onFilterChange={(optionPath, isChecked) => {
                  handleNamespaceChange([option, ...optionPath], isChecked)
                }}
                isFirstLayerOption={index !== 0}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
)

export default FilterCategory
