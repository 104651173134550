const Style = {
  // scroll bar thin, track invixilble and thumb white
  container:
    'p-6 overflow-y-auto max-h-full bg-[#eeeeee] scrollbar scrollbar-thumb-[#686864] scrollbar-track-transparent',
  header: 'header h-[5.5rem] relative',
  content: 'content max-w-screen-lg mx-auto border-t p-2 ',
  breadcrumbs: 'breadcrumbs text-sm pr-4 text-[#262626]',
  breadcrumbLink: 'text-primary-dark hover:underline cursor-pointer',
  tools: 'tools flex items-center gap-2 lg:gap-4 w-full lg:w-auto',
  search: {
    wrapper: 'bg-transparent w-full lg:w-auto',
    button: 'hover:opacity-50',
    input:
      'lg:!w-96  h-12 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 focus:border-sky-500/20 focus:outline-none focus:ring-2 focus:ring-sky-500/20 bg-white rounded-lg shadow-sm',
  },
  button:
    'inline !h-12 !w-12 rounded-lg [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 bg-white shadow-sm',
  title: 'text-xl font-bold',
  fullTitle: 'text-primary-dark text-lg text-primary-dark min-w-[300px] max-w-[800px] mx-auto p-4 font-serif',
}
export default Style
