const ButtonStyle = {
  base: 'cursor-pointer font-sans',
  btn: 'block text-center font-semibold rounded-xl px-12 py-3 ease-in-out duration-500 text-white disabled:opacity-50 disabled:cursor-default',
  big: 'md:text-lg',
  small: 'text-sm px-7',
  outline: '!text-white',
  green: 'bg-emerald-500 hover:bg-emerald-600',
  violet: 'bg-violet-500 hover:bg-violet-600',
  cyan: 'bg-cyan-500 hover:bg-cyan-600',
  brand2: 'bg-brand2-500 hover:bg-brand2-600',
  brand3: 'bg-brand-500 hover:bg-blue-900',
  white:
    'bg-white !py-2.5 !px-5 !text-left !text-[#475569] !font-medium rounded-full my-1 drop-shadow-sm border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20',
  brand2outline:
    '!text-white-500 border-solid border border-white-500 hover:!text-brand2-500 hover:border-brand2-500 hover:bg-brand2-500/10 bg-transparent',
  sky: 'bg-sky-500 hover:bg-sky-600',
  brand2: 'bg-brand2-500 hover:bg-brand2-600',
  red: 'bg-red-500 hover:bg-red-600 hover:border-red-500',
  blue: 'bg-blue-500 hover:bg-blue-600 hover:border-blue-600',
  orange: 'bg-orange-500 hover:bg-orange-600 hover:border-orange-600',
  textOnly: 'text-slate-500',
  iconButton: 'inline-block p-0 w-4 h-4',
  iconText: 'pl-12',
  iconTextOnly: 'pl-6',
  fullWidth: '!w-full',
  rounded: 'rounded-full',
  relative: 'relative',
  absolute: 'absolute',
  alignLeft: 'pl-6 pr-0',
  alignRight: 'pl-0 pr-6',
  loading: `relative after:absolute after:w-4 after:h-4 after:right-1 after:top-4 after:z-10 
    after:bg-[url('components/button/icons/ico-loader.svg')] after:bg-contain after:right-2 after:top-3.5 after:origin-center after:animate-loading`,
}

export default ButtonStyle
